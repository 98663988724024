import { StyleSheet, css } from 'aphrodite/no-important';
import React from 'react';

const Nobr = props => {
  return <span className={css(styles.noWrap)}>{props.children}</span>;
};

export default Nobr;

const styles = StyleSheet.create({
  noWrap: {
    whiteSpace: 'nowrap',
  },
});
