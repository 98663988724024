import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';

import { colors, widths } from '../utils/constants';

const Footer = () => {
  return (
    <footer className={css(styles.footer)}>&copy; 2025 Emily Maskin</footer>
  );
};

export default Footer;

const styles = StyleSheet.create({
  footer: {
    backgroundColor: colors.navy,
    color: colors.bgLight,
    fontSize: 10,
    fontWeight: 300,
    padding: '10px 60px',
    [`@media ${widths.mobile}`]: {
      padding: '10px 40px',
    },
    [`@media ${widths.mobileSmall}`]: {
      padding: '10px 30px',
    },
  },
});
