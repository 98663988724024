import React from 'react';
import { Section } from './elements';
import { StyleSheet, css } from 'aphrodite/no-important';
import { colors, widths } from '../utils/constants';
import skillshare from '../img/skillshare.jpg';

const Teaching = () => (
  <Section title="Teaching" id="teaching">
    <div className={css(styles.wrapper)}>
      <div className={css(styles.projectItem)}>
        <a
          className={css(styles.link)}
          href="https://skl.sh/3Xl6T0e"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={css(styles.thumbnail)}
            src={skillshare}
            alt="Learn to Code With HTML and CSS"
          />
          <div>Learn to Code With HTML and CSS</div>
        </a>
      </div>
    </div>
  </Section>
);

export default Teaching;

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    textAlign: 'center',
  },
  projectItem: {
    overflow: 'hidden',
    padding: 15,
    backgroundColor: colors.bgLight,
    border: `1px solid ${colors.lightGray}`,
    margin: '0 auto 20px',
    display: 'inline-block',
    ':hover': {
      borderColor: 'rgba(92, 75, 81, 0.3)',
    },
  },
  link: {
    display: 'block',
    overflow: 'hidden',
  },
  thumbnail: {
    display: 'block',
    width: 400,
    border: '1px solid rgba(92, 75, 81, 0.1)',
    marginBottom: 15,
    [`@media ${widths.mobile}`]: {
      width: '100%',
      height: 'auto',
    },
  },
});
