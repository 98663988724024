import React from 'react';

import { StyleSheet, css } from 'aphrodite/no-important';

import { colors, widths } from '../utils/constants';

const Nav = props => {
  return (
    <nav className={css(styles.nav)}>
      <span
        className={css(
          styles.sectionLink,
          props.currentSection === 'about' && styles.current,
        )}
        onClick={props.onScrollToSection('about')}
      >
        About
      </span>
      <span
        className={css(
          styles.sectionLink,
          props.currentSection === 'resume' && styles.current,
        )}
        onClick={props.onScrollToSection('resume')}
      >
        R&eacute;sum&eacute;
      </span>
      <span
        className={css(
          styles.sectionLink,
          props.currentSection === 'writing' && styles.current,
        )}
        onClick={props.onScrollToSection('writing')}
      >
        Writing
      </span>
      <span
        className={css(
          styles.sectionLink,
          props.currentSection === 'teaching' && styles.current,
        )}
        onClick={props.onScrollToSection('teaching')}
      >
        Teaching
      </span>
      <span className={css(styles.social)}>
        <a
          href="http://github.com/emilymaskin"
          title="Github"
          target="_blank"
          rel="noopener noreferrer"
          className={css(styles.socialIcon)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            enableBackground="new 0 0 40 40"
            className={css(styles.svg)}
          >
            <path
              fill="#f9f8f4"
              d="M40 7.5v25c0 2.1-.7 3.8-2.2 5.3-1.5 1.5-3.2 2.2-5.3 2.2H26.1c-.1 0-.3-.1-.5-.1s-.3-.2-.4-.4c-.2-.2-.2-.5-.2-.7v-6.2c0-1.7-.5-2.9-1.4-3.7 1-.1 1.9-.3 2.7-.5.8-.2 1.6-.5 2.4-1 .8-.5 1.5-1 2.1-1.7.6-.7 1-1.6 1.4-2.7.4-1.1.5-2.4.5-3.9 0-2.1-.7-3.9-2.1-5.4.6-1.6.6-3.4-.2-5.3-.5-.2-1.2-.1-2.1.3-.9.3-1.7.7-2.4 1.1l-1 .6c-1.6-.5-3.3-.7-5-.7s-3.4.2-5 .7c-.3-.2-.6-.4-1.1-.7-.5-.3-1.2-.6-2.2-1s-1.6-.6-2.1-.4c-.8 2-.8 3.7-.2 5.3-1.4 1.5-2.1 3.3-2.1 5.4 0 1.5.2 2.8.5 3.9s.8 2 1.4 2.7c.6.7 1.3 1.3 2.1 1.7s1.7.8 2.4 1c.8.2 1.7.4 2.7.5-.7.6-1.1 1.5-1.3 2.7-.4.2-.8.3-1.2.4-.3.1-.8.1-1.4.1s-1.1-.2-1.7-.6c-.6-.4-1-.9-1.4-1.6-.3-.6-.8-1-1.3-1.4s-.9-.6-1.3-.6l-.5-.1c-.4 0-.6 0-.8.1-.1.1-.2.2-.1.3.1.1.1.2.2.4l.3.3.3.2c.4.2.8.5 1.1 1 .4.5.6.9.8 1.3l.3.6c.2.7.6 1.2 1.1 1.6.5.4 1.1.7 1.7.8.6.1 1.2.2 1.8.2.6 0 1.1 0 1.4-.1l.6-.1v4.5c0 .4-.1.7-.3.9-.2.2-.4.3-.6.3H7.5c-2.1 0-3.8-.7-5.3-2.2C.7 36.3 0 34.6 0 32.5v-25c0-2.1.7-3.8 2.2-5.3S5.4 0 7.5 0h25c2.1 0 3.8.7 5.3 2.2S40 5.4 40 7.5z"
            />
          </svg>
        </a>
        <a
          href="http://linkedin.com/in/emilymaskin"
          title="LinkedIn"
          target="_blank"
          rel="noopener noreferrer"
          className={css(styles.socialIcon)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            enableBackground="new 0 0 40 40"
            className={css(styles.svg)}
          >
            <path
              fill="#f9f8f4"
              d="M6.2 33.5h6V15.4h-6v18.1zm6.4-23.7c0-.9-.3-1.6-.9-2.2-.7-.6-1.5-.9-2.5-.9s-1.8.3-2.5.9c-.6.6-1 1.3-1 2.2 0 .9.3 1.6.9 2.2s1.6 1 2.5 1c1 0 1.8-.3 2.5-.9s1-1.4 1-2.3zm15.2 23.7h6V23.1c0-2.7-.6-4.7-1.9-6.1-1.3-1.4-2.9-2.1-5-2.1-2.4 0-4.2 1-5.4 3h.1v-2.6h-6c.1 1.1.1 7.2 0 18.1h6v-10c0-.7.1-1.1.2-1.5.3-.6.7-1.1 1.2-1.5.5-.4 1.2-.6 1.9-.6 2 0 3 1.4 3 4.1v9.6zM40 7.5v25c0 2.1-.7 3.8-2.2 5.3-1.5 1.5-3.2 2.2-5.3 2.2h-25c-2.1 0-3.8-.7-5.3-2.2S0 34.6 0 32.5v-25c0-2.1.7-3.8 2.2-5.3S5.4 0 7.5 0h25c2.1 0 3.8.7 5.3 2.2C39.3 3.7 40 5.4 40 7.5z"
            />
          </svg>
        </a>
      </span>
    </nav>
  );
};

export default Nav;

const styles = StyleSheet.create({
  nav: {
    backgroundColor: colors.navy,
    color: colors.bgLight,
    textTransform: 'uppercase',
    fontSize: 11,
    letterSpacing: 1.6,
    position: 'fixed',
    zIndex: 1000,
    top: 0,
    width: '100%',
    [`@media ${widths.mobile}`]: {
      position: 'relative',
    },
  },
  sectionLink: {
    padding: 18,
    display: 'inline-block',
    cursor: 'pointer',
    [`@media ${widths.mobile}`]: {
      display: 'block',
      textAlign: 'center',
      padding: 12,
    },
    ':hover': {
      backgroundColor: colors.red,
    },
  },
  current: {
    backgroundColor: colors.red,
    cursor: 'default',
  },
  social: {
    float: 'right',
    padding: 13,
    [`@media ${widths.mobile}`]: {
      display: 'none',
    },
  },
  socialIcon: {
    margin: '0 5px',
    display: 'inline-block',
  },
  svg: {
    width: 20,
  },
});
