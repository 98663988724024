import React, { useState } from 'react';
import scrollToElement from 'scroll-to-element';
import './App.css';
import {
  About,
  Footer,
  Header,
  Nav,
  Resume,
  Teaching,
  Writing,
} from './components/';

const App = () => {
  const [currentSection, setCurrentSection] = useState('about');

  const onScrollToSection = id => () => {
    scrollToElement(`#${id}`, {
      offset: -50,
      duration: 300,
    });
    setCurrentSection(id);
  };

  return (
    <div className="App">
      <Nav
        currentSection={currentSection}
        onScrollToSection={onScrollToSection}
      />
      <Header />
      <About onScrollToSection={onScrollToSection} />
      <Resume />
      <Writing />
      <Teaching />
      <Footer />
    </div>
  );
};

export default App;
